<template>
  <div class="userManage">
    <el-card class="box-card father-card" shadow="never">
      <!-- 头部区域 -->
      <el-row class="header">
        <el-form ref="form" :model="form" inline>
          <!-- 搜索框 -->
          <el-form-item label="用户名">
            <el-input v-model="form.userName" placeholder="请输入用户名" clearable></el-input>
          </el-form-item>
          <el-form-item label="电话">
            <el-input v-model="form.mobilePhone" placeholder="请输入电话" clearable></el-input>
          </el-form-item>
          <!-- 按钮区域 -->
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="search()">搜索</el-button>
            <el-button
              type="primary"
              icon="el-icon-plus"
              @click="add()"
              v-if="btnList.includes(97)"
            >添加</el-button>

            <!-- <el-button type="primary" icon="el-icon-search">重置密码</el-button> -->
          </el-form-item>
        </el-form>
      </el-row>
      <el-row class="content">
        <!-- 表格区域 -->
        <el-table
          ref="userTable"
          :data="userList"
          tooltip-effect="dark"
          style="width: 100%"
          highlight-current-row
          stripe
          border
          :header-cell-style="rowClass"
        >
          <!-- <el-table-column prop="account" label="用户账号" align="center"></el-table-column> -->
          <!-- <el-table-column prop="uuid" label="id" align="center"></el-table-column> -->
          <el-table-column prop="userName" label="用户名" align="center"></el-table-column>
          <el-table-column prop="companyName" label="机构名" align="center"></el-table-column>
          <el-table-column prop="roleName" label="用户角色" align="center">
            <!-- <template slot-scope="scope">
              <el-tag effect="dark" v-if="scope.row.userType === '01'">{{ "平台管理员" }}</el-tag>
              <el-tag
                type="success"
                effect="dark"
                v-else-if="scope.row.userType === '02'"
              >{{ "平台调度员" }}</el-tag>
              <el-tag type="warning" effect="dark" v-else>
                {{
                "企业用户"
                }}
              </el-tag>
            </template>-->
          </el-table-column>
          <el-table-column prop="mobilePhone" label="电话" align="center"></el-table-column>
          <!-- <el-table-column prop="passwd" label="登录密码" align="center"></el-table-column> -->
          <el-table-column prop="userStatus" label="状态" align="center">
            <template slot-scope="scope">
              <span effect="dark" v-if="scope.row.userStatus === 0">{{ "新增" }}</span>
              <span effect="dark" type="success" v-else-if="scope.row.userStatus === 1">{{ "正常" }}</span>
              <span type="info" effect="dark" v-else>
                {{
                "停用"
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" min-width="200px">
            <template slot-scope="scope">
              <el-button size="mini" type="success" @click="look(scope.row)">查看详情</el-button>
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                @click="edit(scope.row)"
                v-if="btnList.includes(98)"
              >修改</el-button>
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete-solid"
                @click="open(scope.row)"
                v-if="btnList.includes(99)"
              >删除</el-button>
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete-solid"
                @click="editUserTap(scope.row)"
                v-if="btnList.includes(99)"
              >修改密码</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handlePageNumChange"
          :current-page="paging.PageNum"
          :page-sizes="[10,15, 20, 50]"
          :page-size="paging.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </el-row>
      <!-- 添加用户弹出框 -->
      <el-dialog :close-on-click-modal="false" :visible.sync="addDialogFormVisible" class="add-dialog" @close="addDialogClose()">
        <span slot="title" class="dialog-Title">添加用户</span>
        <el-form :model="addForm" inline ref="addForm" :rules="addFormRules">
          <!-- 第一行 -->
          <el-row>
            <el-form-item label="用户名" prop="userName">
              <el-input v-model="addForm.userName" autocomplete="off" clearable></el-input>
            </el-form-item>
            <el-form-item label="所属机构" prop="corpId">
              <el-select v-model="addForm.corpId" placeholder="请选择" filterable clearable>
                <el-option
                  v-for="(item, index) in orderCompanyList"
                  :key="index"
                  :label="item.companyName"
                  :value="item.uuid"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <!-- 第二行 -->
          <el-row>
            <el-form-item label="角色类型" prop="roleId">
              <el-select v-model="addForm.roleId" placeholder="请选择角色类型" filterable>
                <el-option
                  :label="item.roleName"
                  :value="item.uuid"
                  v-for="(item, index) in useTypeList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="联系电话" prop="mobilePhone">
              <el-input
                v-model="addForm.mobilePhone"
                autocomplete="off"

                onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
                maxlength="11"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="userStatus">
              <el-select v-model="addForm.userStatus" placeholder="请选择状态" filterable>
                <el-option label="新增" value="0"></el-option>
                <el-option label="正常" value="1"></el-option>
                <el-option label="停用" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <!-- 第三行 -->
          <el-row>
            <el-form-item label="有效时间">
              <!-- <el-date-picker
                                v-model="addForm.dateFrom"
                                type="datetime"
                                placeholder="选择日期时间"
                                align="right"
                                :picker-options="pickerOptions"
                            >
              </el-date-picker>-->
              <el-date-picker
                v-model="addForm.date"
                type="datetimerange"
                :picker-options="pickerOptions"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right"

              ></el-date-picker>
            </el-form-item>
            <!-- <el-form-item label="失效时间">
                            <el-input
                                v-model="addForm.dateTo"
                                autocomplete="off"
                            ></el-input>
            </el-form-item>-->
            <!-- <el-form-item label="激活时间">
                            <el-input
                                v-model="addForm.activedate"
                                autocomplete="off"
                            ></el-input>
            </el-form-item>-->
          </el-row>
          <!-- 第四行 -->
          <el-row>
            <el-form-item label="联系邮箱">
              <el-input v-model="addForm.email" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model="addForm.remark" autocomplete="off"></el-input>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="info" @click="addDialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="addUser()">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 编辑用户弹出框 -->
      <el-dialog
       :close-on-click-modal="false"
        :visible.sync="editDialogFormVisible"
        class="edit-dialog"
        @close="editDialogClose()"
      >
        <span slot="title" class="dialog-Title">编辑用户信息</span>
        <el-form :model="editForm" inline ref="editForms" :rules="editFormRules">
          <!-- 第一行 -->
          <el-row>
            <el-form-item label="用户名" prop="userName">
              <el-input v-model="editForm.userName" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
            <el-form-item label="所属机构" prop="corpId">
              <el-select
                v-model="editForm.corpId"
                placeholder="请选择所属机构"
                filterable
                clearable
                :disabled="disabled"
              >
                <el-option
                  v-for="(item, index) in orderCompanyList"
                  :key="index"
                  :label="item.companyName"
                  :value="item.uuid"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="用户类型" prop="roleId">
              <el-select
                v-model="editForm.roleId"
                placeholder="请选择用户类型"
                filterable
                :disabled="disabled"
              >
                <el-option
                  :label="item.roleName"
                  :value="item.uuid"
                  v-for="(item, index) in useTypeList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <!-- 第二行 -->
          <el-row>
            <el-form-item label="联系邮箱">
              <el-input v-model="editForm.email" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="mobilePhone">
              <el-input
                v-model="editForm.mobilePhone"
                autocomplete="off"
                :disabled="disabled"
                onkeyup="value=value.replace(/[^\d]/g,'')"
                maxlength="11"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-select
                v-model="editForm.userStatus"
                placeholder="请选择状态"
                filterable
                :disabled="disabled"
              >
                <el-option label="新增" :value="0"></el-option>
                <el-option label="正常" :value="1"></el-option>
                <el-option label="停用" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <!-- 第三行 -->
          <el-row>
            <!-- <el-form-item label="生效时间">
              <el-input v-model="editForm.dateFrom" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
            <el-form-item label="失效时间">
              <el-input v-model="editForm.dateTo" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item> -->
             <el-form-item label="有效时间">
                 <el-date-picker
                v-model="editForm.timeData"
                type="datetimerange"
                :picker-options="pickerOptions"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right"
                :disabled="disabled"

              ></el-date-picker>
             </el-form-item>

            <el-form-item label="激活时间">
              <el-input v-model="editForm.activedate" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>

          </el-row>
          <!-- 第四行 -->
          <el-row>
            <el-form-item label="备注">
              <el-input v-model="editForm.remark" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="info" @click="editDialogFormVisible = false">{{disabled?'关闭':'取消'}}</el-button>
          <el-button v-show="!disabled" type="primary" @click="editUser()">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 修改密码弹出框 -->
      <el-dialog
      :close-on-click-modal="false"
        :visible.sync="editUserFormVisible"
        class="edit-dialog"
        @close="editDialogClose()"
      >
        <span slot="title" class="dialog-Title">修改用户密码</span>
        <el-form :model="editUserForm" inline ref="editForms" :rules="editRules">
          <!-- 第一行 -->
          <el-row>
            <el-form-item label="用户名">
              <el-input v-model="editUserForm.userName" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input v-model="editUserForm.mobilePhone" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
          </el-row>
          <el-row>
             <!-- <el-form-item label="原密码">
              <el-input v-model="editUserForm.passWord" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item> -->
            <el-form-item prop="passWord1" label="新密码">
              <el-input type="password" v-model="editUserForm.passWord1" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item prop="passWord2" label="确认新密码">
              <el-input type="password" v-model="editUserForm.passWord2" autocomplete="off"></el-input>
            </el-form-item>
          </el-row>

        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="info" @click="editUserFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="editUserPassWd('editForms')">确 定</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import _ from 'lodash'
var validatePass = (rule, value, callback) => {
  // 必须包含⼤⼩写字母、数字、特殊字符长度再9-16位之间
  var regex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{9,16}')
  if (value === '') {
    callback(new Error('请输⼊密码'))
  } else if (value.length < 8) {
    callback(new Error('密码最少8位'))
  } else if (!regex.test(value)) {
    callback(new Error('密码必须同时包含字母、数字和特殊字符其中三项且⾄少8位'))
  } else {
    callback()
  }
}
function checkPhone (rule, value, callback) { // 手机号验证
  if (!value) {
    return callback(new Error('手机号不能为空'))
  } else {
    const reg = /^1[3456789]\d{9}$/
    if (reg.test(value)) {
      callback()
    } else {
      return callback(new Error('请输入正确的手机号'))
    }
  }
}
function dateFormat (date, format) {
  date = new Date(date)
  date.setHours(date.getHours() - 14)
  var o = {
    'M+': date.getMonth() + 1, // month
    'd+': date.getDate(), // day
    'H+': date.getHours(), // hour
    'm+': date.getMinutes(), // minute
    's+': date.getSeconds(), // second
    'q+': Math.floor((date.getMonth() + 3) / 3), // quarter
    S: date.getMilliseconds() // millisecond
  }

  if (/(y+)/.test(format)) { format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length)) }

  for (var k in o) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
    }
  }
  return format
}
export default {
  name: 'userManage',
  components: {

  },

  data () {
    return {
      form: {
        userName: ''
      }, // 搜索框表单
      selectForm: {}, // 选中项对象
      total: 100, // 总数据条数
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNum: 1 // 当前页数
      }, // 分页表单
      userList: [], // 用户列表
      addDialogFormVisible: false, // 控制添加用户弹出框的显示与隐藏
      editDialogFormVisible: false, // 控制编辑弹出框的显示与隐藏
      editUserFormVisible: false,
      addForm: {
        userName: '', mobilePhone: '', roleId: '', email: '', date: '', userStatus: '', corpId: ''
      },
      editUserForm: {
        userName: '', mobilePhone: '', passWord: '', passWord1: '', passWord2: '', corpId: ''
      },
      // 添加用户弹出框表单
      addFormRules: {
        userName: [
          { required: true, message: '请输入用户姓名', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
        passwd: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        roleId: [
          { required: true, message: '请选择角色', trigger: 'blur' }
        ],
        corpId: [
          { required: true, message: '请选择企业', trigger: 'blur' }
        ],
        userType: [
          { required: true, message: '请选择用户类型', trigger: 'blur' }
        ],
        mobilePhone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' }
        ],
        userStatus: [
          { required: true, message: '请选择状态', trigger: 'blur' }
        ]
      }, // 添加角色表单验证规则
      editForm: {
        userName: '', mobilePhone: '', roleId: '', email: '', userStatus: 1, roleName: '', uuid: 1, timeData: []
      }, // 编辑弹出框表单
      editFormRules: {
        userName: [
          { required: true, message: '请输入用户姓名', trigger: 'blur' },
          { min: 2, max: 8, message: '长度在 2 到 8 个字符', trigger: 'blur' }
        ],
        roleCode: [
          { required: true, message: '请选择角色', trigger: 'blur' }
        ],
        corpId: [
          { required: true, message: '请选择企业', trigger: 'blur' }
        ],
        userType: [
          { required: true, message: '请选择用户类型', trigger: 'blur' }
        ],
        mobilePhone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' }
        ],
        userStatus: [
          { required: true, message: '请选择状态', trigger: 'blur' }
        ]
      }, // 编辑用户表单验证规则
      delForm: { uuid: '', userName: '' }, // 删除用户表单
      useTypeList: [{ roleName: '平台管理员', uuid: '01' }, { roleName: '平台调度员', uuid: '02' }, { roleName: '企业用户', uuid: '03' }], // 用户类型
      disabled: true,
      orderCompanyList: [], // 机构列表
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      }, // 日期时间选择器配置项
      btnList: sessionStorage.getItem('btnList'),
      editRules: {
        passWord1: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        passWord2: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getUserList()
    this.getCateList()
    this.getorderCompany()
  },
  methods: {
    transformTime (timestamp) {
      const a = new Date(timestamp).getTime()
      const date = new Date(a)
      const Y = date.getFullYear() + '-'
      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '  '
      const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
      // const s = date.getSeconds(); // 秒
      const dateString = Y + M + D + h + m
      // console.log('dateString', dateString); // > dateString 2021-07-06 14:23
      return dateString
    },
    // 给表格头部设定背景色
    rowClass ({ row, rowIndex }) {
      return 'background:#ccc'
    },
    // 获取所有用户---已调试完成
    async getUserList () {
      const { data: res } = await this.$http.post('/userManageServer/user/selectUserList', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNum
      })
      console.log(res)
      if (res.statusCode !== '200') return this.$message.error('获取用户列表失败')
      this.userList = res.data
      this.total = +res.pageBean.pageDataCount
    },
    // 获取所有角色---已调试完成
    async getCateList () {
      const { data: res } = await this.$http.post('/userManageServer/role/selectRoleList', {
        pageNo: 1,
        pageSize: 100,
        applicationCode: '10000',
        status: 1
      })
      if (res.statusCode !== '200') {
        return this.$message.error('获取数据失败！')
      }
      this.useTypeList = res.data
    },
    // 切换每页显示数量时触发
    handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      this.getUserList()
    },
    // 切换页码时触发
    handlePageNumChange (PageNum) {
      this.paging.PageNum = PageNum
      this.getUserList()
    },
    // 点击搜索按钮触发---已调试完成
    async search () {
      // 判断有输入账号
    //   if (this.form.userName === '' && this.form.mobilePhone === '') {
    //     this.getUserList()
    //   } else {
      // 调接口，进行查询
      this.paging.PageNum = 1
      const { data: res } = await this.$http.post('/userManageServer/user/selectUserList', {
        // userName: this.form.userName,
        // mobilePhone: this.form.mobilePhone,
        ...this.form,
        pageNo: this.paging.PageNum,
        pageSize: this.paging.pageSize
      })
      // console.log(res);
      // 判断请求是否成功
      if (res.statusCode !== '200') return false
      this.userList = res.data
      this.total = +res.pageBean.pageDataCount
    //   }
    },
    // 点击修改密码触发
    editUserTap (v) {
      this.editUserForm.userName = v.userName
      this.editUserForm.mobilePhone = v.mobilePhone
      this.editUserForm.passWord = v.passwd
      this.editUserFormVisible = true
    },
    async editUserPassWd (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.editUserForm.passWord1 != this.editUserForm.passWord2 && this.editUserForm.passWord1) {
            this.$message.error('两次密码输入不一致，请重新输入！')
            return false
          }
          console.log(this.editUserForm)
          const { data: res } = await this.$http.post('userManageServer/user/updateUserPassWord', {
            userName: this.editUserForm.userName,
            mobilePhone: this.editUserForm.mobilePhone,
            passWord: this.editUserForm.passWord1
          })
          if (res.statusCode !== '200') return this.$message.error('修改密码失败')
          // 重新渲染数据
          this.getUserList()
          this.editUserFormVisible = false
          this.$message.success('修改密码成功')
        } else {
          console.log('error submit!!')
          return false
        }
      })
      //   if (!this.editUserForm.passWord1) {
      //     this.$message.error('新密码不能为空！')
      //     return false
      //   }
      //   if (this.editUserForm.passWord1.trim().length < 3 || this.editUserForm.passWord1.trim().length > 18) {
      //     this.$message.error('密码长度应为3-18个字符')
      //     return false
      //   }
    },
    // 点击添加按钮触发
    add () {
      this.addDialogFormVisible = true
    },
    // 添加用户--确定按钮---已调试完成
    addUser () {
      // 预校验
      this.$refs.addForm.validate(async valid => {
        if (!valid) return
        if (this.addForm.date) {
        //   this.addForm.dateFrom = Date.parse(new Date(this.addForm.date[0]).toString())
        //   this.addForm.dateTo = Date.parse(new Date(this.addForm.date[1]).toString())
          this.addForm.dateFrom = this.addForm.date[0].valueOf()
          this.addForm.dateTo = this.addForm.date[1].valueOf()
        } else {
          this.addForm.dateFrom = ''
          this.addForm.dateTo = ''
        }
        // 调接口，发送添加客户请求
        const { data } = await this.$http.post('/userManageServer/user/addUser', JSON.parse(JSON.stringify(this.addForm)))
        // 判断请求是否成功
        if (data.statusCode !== '200') return this.$message.error('添加客户失败')
        // 成功提示
        this.$message.success('添加用户成功')
        // 重新渲染数据
        this.getUserList()
        // 关闭弹框
        this.addDialogFormVisible = false
      })
    },
    // 监听添加用户对话框的关闭事件
    addDialogClose () {
      this.$refs.addForm.resetFields()
    },
    // 监听编辑用户对话框的关闭事件
    editDialogClose () {
      // this.$refs.editForms.resetFields()
    },
    // 点击查看详情按钮触发
    look (v) {
      this.disabled = true
      this.editForm = _.cloneDeep(v)
      this.editDialogFormVisible = true
      this.editForm.timeData = []
      if (this.editForm.dateFrom) {
        this.editForm.timeData.push(this.editForm.dateFrom)
      }
      if (this.editForm.dateTo) {
        this.editForm.timeData.push(this.editForm.dateTo)
      }
    },
    // 点击编辑按钮触发
    edit (v) {
      this.disabled = false
      this.editForm = _.cloneDeep(v)
      this.editDialogFormVisible = true
      const { dateFrom, dateTo } = this.editForm
      //   this.editForm.timeData = []
      this.$set(this.editForm, 'timeData', [])
      if (dateFrom) {
        this.editForm.timeData.push(dateFrom)
      }
      if (dateTo) {
        this.editForm.timeData.push(dateTo)
      }
      console.log(this.editForm.timeData, 'this.editForm.timeData')
    },
    // 编辑客户--确定按钮---已调试完成
    editUser () {
      console.log(JSON.parse(JSON.stringify(this.editForm)))
      // 预校验
      this.$refs.editForms.validate(async valid => {
        if (!valid) return
        if (this.editForm.timeData && this.editForm.timeData.length != 0) {
          console.log(this.editForm.timeData[0], 'this.editForm.timeData')
          this.editForm.dateFrom = this.editForm.timeData[0]
          this.editForm.dateTo = this.editForm.timeData[1]
        } else {
          this.editForm.dateFrom = ''
          this.editForm.dateTo = ''
        }

        console.log(JSON.stringify(this.editForm))
        // 调接口，发送编辑客户信息请求
        const { data } = await this.$http.post('/userManageServer/user/updateUser', JSON.parse(JSON.stringify(this.editForm)))
        // 判断请求是否成功
        if (data.statusCode !== '200') return this.$message.error('编辑用户信息失败')
        this.$message.success('编辑成功')
        // 重新渲染数据
        this.getUserList()
        // 关闭弹框
        this.editDialogFormVisible = false
      })
    },
    // 点击删除按钮触发---已调试完成
    del () {
      // 判断是否选择编删除对象
      if (this.delForm.uuid === '') return this.$message.error('请先选择删除对象')
      // 显示删除确认框
      this.open(this.delForm)
    },
    open (e) {
      this.$confirm(`是否确认删除：${e.userName}`, '删除用户', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消删除',
        type: 'warning'
      }).then(async () => {
        // console.log(e.uuid);
        // 发请求，删除用户
        try {
          const { data } = await this.$http.post('/userManageServer/user/deleteUser', JSON.parse(JSON.stringify(e)))
          // 判断是否删除成功
          if (data.statusCode !== '200') return this.$message.error('删除用户失败')
          // this.delForm = { uuid: '', userName: '' }
          // 成功提示
          this.$message.success('删除用户成功')
          // 刷新视图
          this.getUserList()
        } catch (e) {
          // console.log(e)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 按地区获取机构--调试完成
    async getorderCompany (e) {
      const { data: res } = await this.$http.post('/userManageServer/tSysCompany/selectCompany', {
        // "cityId": 177,
        // "companyName": "岳麓"
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取机构失败')
      this.orderCompanyList = res.data
      // console.log(res);
    }
  }
}
</script>

<style lang="less" scoped>
.userManage {
  width: 100%;
  height: 100%;
  // background-color: pink;
  .father-card {
    // background-color: pink;
    width: 100%;
    height: 100%;
    /deep/ .el-table {
      margin-bottom: 20px;
      .el-table__row--striped td {
        background-color: #e4eaec !important;
      }
      .current-row > td {
        background-color: #66b1ff !important;
      }
    }
    .el-pagination {
      margin-bottom: 0.25rem;
      margin-right: 0.25rem;
      text-align: right;
    }
    .edit-dialog,
    .add-dialog {
      .dialog-Title {
        color: #0097fe;
        font-size: 20px;
        font-weight: 700;
      }
      /deep/ .el-form {
        .subtitle {
          color: #0097fe;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 20px;
          padding-left: 6px;
          border-left: 5px solid #0097fe;
        }
        .el-row {
          display: flex;
          .el-form-item {
            flex: 1;
            display: flex;
            .el-form-item__content {
              flex: 1;
              .el-date-editor {
                width: 100%;
              }
              .el-input {
                width: 100%;
                .el-input__inner {
                  width: 100%;
                }
              }
              .el-select {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
